.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s ease-in-out;
  }
}

.tape-line {
  border-top: .5rem black solid;
  width: 10rem;
}

.tape-line.top {
  margin-bottom: -2.25rem;
}
.tape-line.bottom {
  margin-top: -2.25rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-character {
  font-size: 10rem;
}

.invalid-feedback {
  display: block;
}